import React from 'react';
import styled from 'styled-components';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import { WidthWrapper } from 'components/wrappers';
import { BaseTransparentP, HeaderMedium, BaseP, HeaderSmall } from 'components/typography';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const MainTitle = styled.h1`
    padding-top: 100px;
    text-align: center;
    font-size: 30px;
  }
`;

const Wrapper = styled(WidthWrapper).attrs({ size: 980 })`
	padding-bottom: 100px;

  	${HeaderMedium} {
	    padding-top: 100px;
	    text-align: center;
    	font-size:30px;
    }

    ${HeaderSmall} {
    	font-size:18px;
   		padding-top: 20px;
    }

   	p {
   		line-height: 25px;
   		text-indent: 50px;
   		padding-top: 10px;
   		text-align: justify;
   	}

   	strong {
   		padding-top: 10px;
   	}

   	strong.list {
	    padding-left: 50px;
	}

	.link{
		color: #3a9bef;
	}

	ul {
		padding-left: 40px;
		padding-right: 20px;
		font-size: 17px;
		text-align: justify;
		list-style-type: lower-alpha;
    }

    .sub-list {
		font-size: 17px;
		text-align: justify;
		list-style: lower-roman;
		padding-top: 10px;
		padding-bottom: 10px;
    }
`;
const Breadcrumbs = styled.p`
    margin-bottom:25px;
    margin-top:25px;
    margin-left: 50px;
	width:100%
`;

const TermsPage = () => {
  const intl = useIntl()
  return (
	
  <Layout>
	
    <Helmet>
      {/* <title>Terms and Conditions | Shipkoo</title> */}
	  <title>{intl.formatMessage({ id: "meta-title-terms-and-conditions" })}</title>
      <link rel="alternate" href={intl.formatMessage({ id: "terms-link" })} hreflang={intl.formatMessage({ id: "language" })} />
      <link rel="alternate" href={intl.formatMessage({ id: "terms-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
      <link rel="alternate" href={intl.formatMessage({ id: "terms-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
      <link rel="alternate" href={intl.formatMessage({ id: "terms-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
      <meta name="description" content="Shipkoo owns and operates the platform providing parcel tracking solutions and order management system for merchants through its websites." />
      <meta property="og:title" content={intl.formatMessage({ id: "meta-title-terms-and-conditions" })} />
      <meta property="og:description" content="Shipkoo owns and operates the platform providing parcel tracking solutions and order management system for merchants through its websites." />
      <meta name="twitter:title" content={intl.formatMessage({ id: "meta-title-terms-and-conditions" })} />
      <meta name="twitter:description" content="Shipkoo owns and operates the platform providing parcel tracking solutions and order management system for merchants through its websites." />
    </Helmet>
	<Breadcrumbs><Link to="/">Home</Link> / Terms and Conditions</Breadcrumbs>
  	<Wrapper>
  		<MainTitle>Shipkoo Terms of Service</MainTitle>
  		<HeaderSmall>Introduction</HeaderSmall>
  		<p>
  			Shipkoo HK Limited (Company Registration Number: 2888585) is a company incorporated in Hong Kong. Shipkoo owns and operates the technology platform (“Platform”) providing parcel tracking solutions and order management system for merchants (“Service(s)”) through its websites at <Link to="/" className="link">https://www.shipkoo.com</Link> and <a href="http://www.app.shipkoo.com" className="link">http://www.app.shipkoo.com</a>.
  		</p>

  		<HeaderSmall>1. Applicability</HeaderSmall>
  		<p>
  			The terms and conditions of these Terms of Service govern the services provided by Shipkoo to the Client. These Terms of Service should be read together with any Statement of Work entered into between Shipkoo and the Client. Each Statement of Work and Terms of Service (collectively, this “Agreement comprise the entire agreement between the parties, and supersede all prior or contemporaneous understandings, agreements, negotiations, representations and warranties, and communications, both written and oral. If and to the extent that the Terms of Service conflict with the Statement of Work, the Statement of Work shall prevail. The Terms of Service should be read together with our Privacy Policy.
		</p>
  		<p>
  			Capitalized terms used but not defined in these Terms of Service shall have the meanings set out in the Statement of Work.
		</p>
  		<p>
  			References to ‘we’, ‘us’ and ‘our’ are to Shipkoo and references to ‘you’ and ‘your’ are to the Client.
		</p>

  		<HeaderSmall>2. Our Services</HeaderSmall>
  		<p>
  			Shipkoo shall provide the services to the Client as described in the Statement of Work (the “Services”) in accordance with these Terms of Service. Shipkoo will perform the Services with reasonable skill and care.
  		</p>
  		<p>
  			We may outsource certain functions and work in relation to the Services to third party carriers, couriers, customs brokers, agents and others to which the Client’s goods and packaging materials (the “Client Goods”) are entrusted for transportation, packaging, handling, delivery, storage or otherwise (“Third Parties”) either locally or abroad. Where this occurs, we will take all reasonable steps to ensure that those Third Parties recognise and comply with their obligations of confidentiality. You consent to such outsourcing arrangements including the transfer of any personal data to such Third Parties. Shipkoo shall not be liable or responsible for any negligence, malpractice, fault, errors or omissions in the performance of the Services by any Third Parties.
  		</p>

  		<HeaderSmall>3. Client Obligations</HeaderSmall>
  		<strong>The Client shall:</strong>
  		<ul>
  			<li>Cooperate with Shipkoo in all matters relating to the Services and provide such materials and/or information as may be required by Shipkoo for the purposes of carrying out the Services in a timely manner and ensure that such materials or information are complete and accurate;</li>
  			<li>Respond promptly to Shipkoo’s requests to provide direction, information, approvals, authorizations or decisions that are reasonably necessary for Shipkoo to carry out the Services in accordance with the requirements of these Terms of Service; and;</li>
  			<li>Obtain and maintain all necessary licenses and consents and comply with all applicable laws in relation to Shipkoo’s services before the date on which the Services are to commence.</li>
  		</ul>

  		<HeaderSmall>4. Rate Cards and Cost Simulation</HeaderSmall>
  		<ul>
  			<li>Shipkoo shall provide the client with a rate card (“rate card”) and/or an initial cost simulation based on the information provided by the client during the engagement process (the “cost simulation”). rate cards or cost simulations provided by Shipkoo to the client are for informational purposes only and are subject to change without notice. the rate cards and/or cost simulation shall not be binding upon Shipkoo.</li>
  			<li>typically, known surcharges such as fuel surcharge and delivery area surcharges will be included in the shipping price at the time of shipment. the amount set out in the final billing invoice may differ from the cost simulation as the final chargeable amount may vary based upon a number of factors including, inter alia, incomplete or inaccurate product information, returns to sender, changes in couriers or shipping prices and additional services requested by the client. therefore, Shipkoo reserves the right to bill for, and the client shall be responsible for, additional fees including, without limitation, customs and brokerage fees, additional delivery attempts and other carrier adjustments that are billed to Shipkoo by third parties.</li>
  		</ul>

  		<HeaderSmall>5. Shipkoo Platform Platform</HeaderSmall>
  		<ul>
  			<li>As part of the Services provided by Shipkoo, the Client may integrate or connect its online shopping cart or ecommerce platform with Shipkoo’s cloud-based Platform (Platform). The Shipkoo Platform may be updated and modified from time to time, often without prior notice to you. Your continued use of the Platform constitutes your acceptance of such modifications.</li>
  			<li>The Client may specify one or more administrators (each an “Administrator”) to manage its account on the Shipkoo Platform. Administrators have the ability to access, monitor, use, export and disclose content on the Shipkoo Platform. The Client is responsible for</li>

			<ul className="sub-list">
				<li>the selection of its Administrator(s);</li>
				<li>maintaining the confidentiality of passwords and Administrator accounts;</li>
				<li>managing access to Administrator accounts; and</li>
				<li>ensuring that each Administrator’s use of the Shipkoo Platform complies with these Terms of Service. Shipkoo shall not be held liable for any actions on the part of the Client’s Administrator(s).</li>
			</ul>
  		</ul>

  		<HeaderSmall>6. Inbound Goods and Receiving Policy</HeaderSmall>
  		<ul>
  			<li>Shipkoo may require the Client to send product samples to Shipkoo’s office address for inspection before confirmation of the Inbound Date.</li>
  			<li>The Client shall be responsible for arranging inbound shipment of the Client Goods to Shipkoo’s warehouse and for all related transportation and handling charges with relevant third parties, including duties, customs or charges related to the Client Goods.</li>
  			<li>The Client shall be responsible for ensuring that all Client Goods are properly packaged and correctly labelled in accordance with Shipkoo’s labeling requirements as set out herein or on Shipkoo’s website before delivery to Shipkoo’s warehouse.</li>
  			<li>Shipkoo shall not be liable for any discrepancy in the quantity, quality or condition of the Client Goods that are received at Shipkoo’s warehouse. Receiving checks for incoming Client Goods will be based on a carton level, provided that each carton is clearly and properly labelled, unless there is a prior agreement between the Client and Shipkoo for a per piece inventory count. The Client shall be responsible for insuring all inbound shipment of Client Goods.</li>
  			<li>Shipkoo may, in its sole discretion, refuse, return or dispose of any inbound shipment that does not comply with its receiving policy or is determined to be illegal, hazardous or otherwise, at the Client’s cost, and shall not be liable or responsible for any loss or damage of any nature to, or related to, such refused goods.</li>
  		</ul>

  		<HeaderSmall>7. Labelling and Orders Policy</HeaderSmall>
  		<ul>
  			<li>The Client shall be responsible for providing such information as Shipkoo may require in accordance with the timeline set out in the Statement of Work. The Client shall ensure that all information provided is accurate and complete. Any inaccurate or incomplete information may result in delays to the Fulfillment Date and Shipkoo shall not be liable for any resulting loss or damage.</li>
  			<li>The Client shall be responsible for sending all order submissions to Shipkoo in accordance with Shipkoo’s requirements and that all information provided is complete and accurate to ensure that shipments are not delayed. Shipkoo shall not be responsible for any order submission errors or delays by the Client. Any amendments made by the Client after.</li>
  			<ul className="sub-list">
  				<li>an order has been approved and is pending fulfillment; or</li>
  				<li>the applicable cut-off time
  			may not be processed and Shipkoo shall not be responsible for any shipping delays or incorrect shipments as a result thereof. Any subsequent requests by the Client to amend shipping labels may result in delays and/or the Client incurring additional charges.</li>
  			</ul>
  			<li>The Client shall be responsible for ensuring that all Client Goods (at both carton and unit level) have the proper and correct barcode label to ensure proper inventory management as well as proper pick and pack and fulfillment processes. Shipkoo shall not be held liable for the accurate record keeping or fulfillment of Client Goods that are not properly and correctly labelled. Shipkoo may label any Client Goods at an additional cost to the Client.</li>
  			<li>Shipkoo’s order edit cut-off time is different according to different route services from Monday to Saturday. Please consult your Shipkoo representative for the cut-off time. Shipkoo aims to process all orders that have been submitted and approved via the Platform Order before the Cut-Off Time within 24 to 48 hours. However, order processing may be delayed during</li>
  			<ul className="sub-list">
  				<li>peak season dates including the month of December and the week of Chinese New Year;</li>
  				<li>public holidays in Hong Kong; or</li>
  				<li>public holidays in Hong Kong; or</li>
  			</ul>
  		</ul>

  		<HeaderSmall>8. Couriers and Shipping Policy</HeaderSmall>
  		<ul>
  			<li>Shipkoo shall not be responsible or liable for delays or failed deliveries and any resulting loss or damage caused by Third Parties selected to render the Services. Delays may be caused by various factors beyond Shipkoo’s control, such as natural disasters, strikes or peak fulfillment periods during holiday seasons. Further, Shipkoo cannot guarantee delivery for international shipments as customs and customs agents can delay, refuse to process, assess brokerage, lose or impose unanticipated customs, tax or duties to international shipments at their discretion.</li>
  			<li>Shipkoo shall use its best judgement in selecting the appropriate packaging and gift packaging for all orders. If the Client has provided ship-ready Client Goods or custom packaging and Shipkoo determines the packaging to be unfit for shipping, Shipkoo may offer additional packaging for an additional fee or return the Client Goods to you at your cost.</li>
  			<li>Shipkoo shall provide tracking numbers to the Client, if applicable, and shall not be held responsible for any delayed or missing tracking updates, as this information is and can only be updated by Third Parties.</li>
  			<li>The Client may request a proof of delivery (“POD”) if there is no status update from any Third Parties for 14 Business Days or if the delivery status does not indicate whether the Client Goods have been delivered after normal postal transit time of 3 to 4 weeks from the fulfillment date. Upon the Client’s request, Shipkoo shall use all reasonable efforts to acquire a POD on behalf of the Client from Third Parties. Shipkoo cannot guarantee that Third Parties will provide a POD and shall not be responsible for any delays caused by Third Parties in providing a POD.</li>
			<li>All claims in connection with damaged or lost Client Goods during delivery shall be brought solely against relevant Third Parties and/or its agents. In connection with any such claim, Shipkoo shall reasonably assist and cooperate with the Client, which may be liable for any charges or costs incurred by Shipkoo. As Third Parties require claims to be submitted within a specific time period, it is the Client’s responsibility to notify Shipkoo in advance of any claims it intends to bring.</li>
  		</ul>

  		<HeaderSmall>9. Duties, taxes or clearance related charges</HeaderSmall>
  		<ul>
  			<li>The Client shall be responsible for all duties, taxes, or clearance related charges for all applicable inbound and outbound shipments of the Client Goods. If the Client chooses to send packages Delivery Duties Paid (DDP) for outbound shipments, the Client shall also be responsible for all administration fees charged by Third Parties for processing such duties, taxes or clearance related charges. Where the Client decides to ship Delivery Duties Unpaid (DDU), no administrative fees for processing duties, taxes or clearances related charges will be charged to the Client.</li>
  			<li>Shipkoo will not provide credit terms to the Client in respect of, inter alia, duties, taxes, and tariffs such as gross or general sales taxes (GST) and value added taxes (VAT) or any related charges. The Client must either prepay such amounts to Shipkoo prior to shipping, or place a security deposit on these amounts.</li>
  		</ul>

  		<HeaderSmall>10. Storage and Inventory Policy</HeaderSmall>
  		<ul>
  			<li>The Client Goods are not insured by Shipkoo against any loss or damage, however caused. Shipkoo strongly recommends that the Client procure insurance for the Client Goods at its own cost.</li>
  			<li>Shipkoo does not anticipate inventory shrinkage for Client Goods held by Shipkoo. However, Shipkoo will have an annual 5% shrinkage allowance based on the stated cost value of the Client Goods held at Shipkoo’s warehouse as set out in the Platform. In the event of inventory loss in excess of the annual inventory shrinkage allowance due to inventory count inaccuracies for which Shipkoo is held legally liable, Shipkoo’s liability shall be limited to the actual value of the Client Goods. In no event shall Shipkoo be liable for any lost sales revenue from the inventory loss due to inventory count inaccuracies.</li>
  			<li>Title to the Client Goods will remain with the Client. Notwithstanding anything herein to the contrary, nothing in this Agreement may be deemed to waive or otherwise limit any lien rights that Shipkoo may have under applicable law with respect to the Client Goods.</li>
  			<li>Shipkoo may move the Client Goods within a warehouse and between warehouses by giving 30 days’ notice. Any request by the Client to move the Client Goods to another warehouse will be at the Client’s own costs.</li>
			<li>Shipkoo reserves the right to dispose or sell the Client Goods by giving 7 Business Days’ notice if the Client fails to collect any Client Goods stored in Shipkoo’s warehouse within a reasonable period after the due date of the final invoice.</li>
  		</ul>

  		<HeaderSmall>11. Fees and Payment Terms</HeaderSmall>
  		<ul>
  			<li>Clients must provide valid credit card details when registering on the Platform.</li>
  			<li>The Client agrees to pay our fees as set out in our invoices in consideration of the Services provided by Shipkoo. Our invoices are to be paid in the currency stated therein.</li>
			<li>Shipkoo shall bill the Client on a monthly, weekly or daily basis as determined by Shipkoo from time to time.</li>
			<li>Shipkoo reserves the right to charge the Client’s credit card account once the Client’s balance exceeds USD 500.</li>
  			<li>Shipkoo’s service pricing is subject to change. A minimum of 30 days’ notice will be provided to the Client in writing before new prices are in effect. Fees charged by Third Parties are subject to change without notice and shall not under any circumstances be binding upon Shipkoo.</li>
  			<li>The Client shall pay all invoiced amounts due to Shipkoo within 7 Business Days from the date of Shipkoo’s invoice. Shipkoo will notify the Client of the accepted payment methods and regularly communicate any changes to such payment methods. The Client shall be responsible for any bank remittance service charges levied and Shipkoo will only credit the final amount received to the Client’s account.</li>
			<li>In the event payments are not received by Shipkoo within 7 Business Days after becoming due, Shipkoo reserves the right to charge interest on any such unpaid amounts at a rate of 1% per month from the date such payment was due until the date paid and suspend performance for all services until payment has been made in full.</li>
			<li>Shipkoo may at its sole discretion, request full payment as a condition for release of the Client Goods. Shipkoo reserves the right to charge the Client for any reasonable charges and services incurred on behalf of the Client, including, without limitation, customs, duties, taxes, remote area delivery type charges, unexpected storage charges, revised billings from Third Parties, and ad-hoc labor requests.</li>
			<li>Documentation handling fees (“Documentation Fee”) will be charged for orders that are shipped on the Client’s own courier accounts instead of Shipkoo’s accounts. The Documentation Fee is a handling fee only and does not replace the work of advice of a professional customs and clearance brokers. Supporting documentation may be requested and this does not waive the Documentation Fee. Shipkoo may publish or change the Documentation Fee at any time without notice.</li>
  		</ul>

  		<HeaderSmall>12. Lien</HeaderSmall>
  		<p>
  			Shipkoo shall have a lien on the Client Goods in Shipkoo’s actual or constructive possession, custody or control in respect of all sums of whatever nature that are due and payable by the Client to Shipkoo, including, but not limited to, interest and legal costs and expenses. Shipkoo may refuse to surrender possession of the Client Goods until all sums due and payable to Shipkoo are paid in full. If such amounts remain unpaid for 30 days after Shipkoo’s demand for payment, Shipkoo may, at its absolute discretion, sell the Client Goods by way of public or private sale or any other method Shipkoo deems appropriate without further notice and apply the net proceeds to the sums owed to Shipkoo. Any surplus from such sale shall be transferred to the Client and the rights of Shipkoo are reserved for any shortfall subsequent to the disposal of the Client Goods. If Shipkoo after a reasonable effort is unable to sell the Client Goods Shipkoo may dispose of them in any lawful manner and shall incur no liability by reason of such disposition.
  		</p>

  		<HeaderSmall>13. Termination</HeaderSmall>
  		<ul>
  			<li>Without affecting any other right or remedy provided under these Terms of Service, Shipkoo may at any time terminate the Agreement with immediate effect by giving written notice to the Client if the Client:</li>
  			<ul className="sub-list">
  				<li>fails to pay any amount when due under this Agreement and such failure continues for 7 Business Days after receipt of written notice of nonpayment;</li>
  				<li>has not otherwise performed or complied with any of the Terms of Service, in whole or in part;</li>
  				<li>suspends, or threatens to suspend, or ceases or threatens to cease to carry on all or a substantial part of its business; or</li>
  				<li>becomes insolvent, files a petition for bankruptcy or commences or has commenced against it proceedings relating to bankruptcy, receivership, reorganization or assignment for the benefit of creditors.</li>
  			</ul>
  			<li>Either Shipkoo or the Client may, without prejudice to its other rights or remedies, terminate this Agreement for any reason upon giving 30 days’ written notice to the other party (the “Termination Notice Period”). The Client may have full or limited access to the Shipkoo Platform during the Termination Notice Period. Shipkoo shall continue to process and fulfill all orders which have been submitted by the Client via the Shipkoo Platform or otherwise during the Termination Notice Period.</li>
			<li>Upon termination of this Agreement for any reason, the Client shall immediately pay Shipkoo any outstanding unpaid invoices and interest due to Shipkoo. Shipkoo shall submit invoices for any Services that it has supplied, but for which no invoice has been submitted, and the Client shall pay these invoices immediately on receipt. Shipkoo shall promptly refund such portion of the sums prepaid by the Client as it relates to the period after expiry or termination on a pro rata basis.</li>
  		</ul>

  		<HeaderSmall>14. Intellectual Property</HeaderSmall>
  		<ul>
  			<li>Shipkoo shall retain ownership of all its patents, rights to inventions, copyright and related rights, trade marks, business names and domain names, rights in get-up, goodwill and the right to sue for passing off, rights in designs, database rights, rights to use, and protect the confidentiality of, confidential information (including know-how), and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part of the world (“Intellectual Property Rights”).</li>
  			<li>The Client shall retain ownership of all Intellectual Property Rights in the Client Goods and any other materials and data supplied by the Client to Shipkoo.</li>
  			<li>You agree that Shipkoo may use and refer to your company, trademarks, service marks, trade names, image, character, logos, domain names and other distinctive brand features or identification in Shipkoo’s marketing and advertising materials.</li>
  		</ul>

  		<HeaderSmall>15. Confidential Information</HeaderSmall>
  		<ul>
  			<li>Neither Shipkoo nor the Client shall disclose to any person any confidential information concerning the business, affairs, customers, clients or suppliers of the other party except to:</li>
  			<ul className="sub-list">
  				<li>its employees, officers, representatives, subcontractors or advisers who need to know such information for the purposes of carrying out the party’s obligations under the Agreement. Each party shall ensure that its employees, officers, representatives, subcontractors or advisers to whom it discloses the other party’s confidential information comply with this Clause 15; and</li>
  				<li>as may be required by law, a court of competent jurisdiction or any governmental or regulatory authority.</li>
  			</ul>
  			<li>Neither party shall use any other party’s confidential information for any purpose other than to perform its obligations under the Agreement</li>
  		</ul>

  		<HeaderSmall>16. Limitation of liability</HeaderSmall>
  		<ul>
			<li>The Client acknowledges and agrees that Shipkoo shall not be responsible or liable for any damage to or loss suffered by the Client whether due to non-delivery or mis-delivery or mis-direction of the Client Goods or for any delay or deviation in respect of the transportation or delivery or other handling of the Client Goods as a result of any act or omission (including, without limitation, any negligence or willful neglect or default) of any Third Parties whether or not selected by or retained by Shipkoo.</li>
			<li>Without prejudice to the Agreement, Shipkoo shall not be responsible or liable for any damage to or loss suffered by the Client unless it is proved that such damage or loss was due to the wilful neglect or wilful default of Shipkoo or its agents, subcontractors and personnel.</li>
			<li>Subject to Clause 16(b), Shipkoo’s total liability to the Client, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising under or in connection with the Agreement shall be limited to the actual value of the Client Goods or the amount paid or payable by the Client to Shipkoo for the Services (excluding fees to Third Parties), whichever is less.</li>
  			<li>The Client agrees that, in entering into this Agreement, either it did not rely on any representations (whether written or oral) of any kind or of any person other than those expressly set out in this Agreement or (if it did rely on any representations, whether written or oral, not expressly set out in this Agreement) that it shall have no remedy in respect of such representations and (in either case) Shipkoo shall not in any circumstances have any liability otherwise than in accordance with the express terms of this Agreement; and</li>
  			<li>Without prejudice to the generality of this Clause 16, Shipkoo shall not in any event, whether under Clause 16(b) or otherwise, be liable to the Client for any incidental, indirect, consequential loss or damage of any kind (including, without limitation, loss of market, profits, sales, agreements, contracts, anticipated savings, business or goodwill) whether in contract, tort (including negligence), for breach of statutory duty, or otherwise.</li>
  		</ul>

  		<HeaderSmall>17. Indemnity</HeaderSmall>
  		<p>
  			The Client agrees to indemnify and to hold harmless Shipkoo and its officers, directors, employees and agents from and against any and all claims, liabilities, damages, losses or expenses, including reasonable attorneys’ fees and costs, due to or arising out of your use of Shipkoo’s Services and/or your violation of any term or condition of the Agreement.
  		</p>

  		<HeaderSmall>18. Force Majeure</HeaderSmall>
  		<ul>
  			<li>Neither party shall be in breach of this Agreement nor liable for delay in performing, or failure to perform, any of its obligations under this Agreement if such delay or failure result from events, circumstances or causes beyond its reasonable control. In such circumstances the affected party shall be entitled to a reasonable extension of the time for performing such obligations. If the period of delay or non-performance continues for one month, the party not affected may terminate this Agreement by giving 14 days’ notice to the affected party.</li>
  			<li>If termination occurs under Clause 18(a), all sums paid to Shipkoo by the Client under this Agreement shall be refunded to the Client, except that Shipkoo shall be entitled to payment on aquantum meruitbasis for all work done before termination, provided that Shipkoo takes all reasonable steps to mitigate the amount due.</li>
  		</ul>

  		<HeaderSmall>19. Severability</HeaderSmall>
  		<p>
  			Each clause and sub-clause of the Agreement shall be independently interpreted and enforceable. If any clause or sub-clause of the Agreement is declared void, illegal or otherwise unenforceable by a court of competent jurisdiction, the remainder shall survive unaffected.
  		</p>

  		<HeaderSmall>20. Counterparts</HeaderSmall>
  		<p>
  			This Agreement may be executed in any number of counterparts, each of which when executed and delivered shall constitute a duplicate original, but all the counterparts shall together constitute the one Agreement.
  		</p>

  		<HeaderSmall>21. Waiver</HeaderSmall>
  		<p>
  			Our failure to exercise or enforce any right or provision of this Agreement, including any failure to act with respect to a breach, will not constitute a waiver of such right or provision or our right to act with respect to subsequent or similar breaches.
  		</p>

  		<HeaderSmall>22. Amendments and Modifications</HeaderSmall>
  		<ul>
  			<li>Any variation to the Services set out in the Statement of Work shall not be effective unless it is in writing and signed by the parties (or their authorised representatives).</li>
  			<li>Shipkoo may amend or replace these Terms of Service from time to time. In such circumstances we shall notify you of the changes and the new or amended Terms of Service will become part of the Agreement 14 days after.</li>
  		</ul>

  		<HeaderSmall>23. Notices</HeaderSmall>
  		<p>
  			Any notices or other communications permitted or required hereunder, including those regarding modifications to these Terms of Service and notices related to the Client’s account, will be in writing and given via email using the email address associated with your account. The date of receipt will be deemed the date on which such notice is transmitted.
  		</p>

  		<HeaderSmall>24. Disputes</HeaderSmall>
  		<p>
  			Should any dispute or claim arise out of or in connection with the Services or this Agreement, we and you will attempt to resolve the dispute or claim in good faith in the first instance by negotiation. If such negotiation is unsuccessful, we and you may agree to seek to resolve the dispute or claim by mediation. If it is not possible to resolve the dispute or claim by negotiation or mediation, then it may be dealt with by legal proceedings or arbitration as provided below. Subject to Clause 25 below, in relation to any legal action or proceedings (“Proceedings”) arising out of or in connection with the Services (whether contractual or non-contractual obligations), each of the parties irrevocably submits to the exclusive jurisdiction of the courts of Hong Kong and waives any objection to Proceedings in such courts on the grounds of venue or on the grounds that Proceedings have been brought in an inappropriate forum.
  		</p>

  		<HeaderSmall>25. Arbitration</HeaderSmall>
  		<p>
  			Any dispute, controversy, difference or claim arising out of or relating to this Agreement, including the existence, validity, interpretation, performance, breach or termination thereof or any dispute regarding non-contractual obligations arising out of or relating to it shall be referred to and finally resolved by arbitration administered by the Hong Kong International Arbitration Centre (HKIAC) under the HKIAC Administered Arbitration Rules in force when the Notice of Arbitration is submitted. The law of this arbitration clause shall be Hong Kong law. The seat of arbitration shall be Hong Kong and the number of arbitrators shall be one. The arbitration proceedings shall be conducted in English.
  		</p>

  		<HeaderSmall>26. Governing Law</HeaderSmall>
  		<p>
  			Any dispute or claim arising out of or in connection with this Agreement or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of the Hong Kong Special Administrative Region.
  		</p>
  	</Wrapper>
  </Layout>
);
};

export default TermsPage;
